import React, { useState, useContext, useEffect } from 'react';
import Utils from '../../utils';
import { Component } from './styles';
import { getProjects } from '../../apiHelper';

const mapProjects = (projects, isSmallDevice) => projects
    .map(
        (i) => {
            let component = null;
            if (i.mainImage) {
                component = (
                    <Component
                        src={i.mainImage}
                        width={i.width}
                        maxWidth={i.maxWidth}
                        height={i.height}
                        objectFitContain={!isSmallDevice}
                    />
                );
            }
            return { component, ...i };
        },
    )
    .sort((a, b) => a.position - b.position);

const prepareProjects = async (
    {
        dayDesktopProjects,
        dayPhoneProjects,
        nightDesktopProjects,
        nightPhoneProjects,
    },
    isSmallDevice,
) => {
    const [
        dayDesktopProjectPrepared,
        dayPhoneProjectPrepared,
        nightDesktopProjectPrepared,
        nightPhoneProjectPrepared,
    ] = await Promise.all([
        mapProjects(dayDesktopProjects, isSmallDevice),
        mapProjects(dayPhoneProjects, isSmallDevice),
        mapProjects(nightDesktopProjects, isSmallDevice),
        mapProjects(nightPhoneProjects, isSmallDevice),
    ]);
    return {
        dayDesktopProjectPrepared,
        dayPhoneProjectPrepared,
        nightDesktopProjectPrepared,
        nightPhoneProjectPrepared,
    };
};

const getProjectsByMode = (
    {
        dayDesktopProjectPrepared,
        dayPhoneProjectPrepared,
        nightDesktopProjectPrepared,
        nightPhoneProjectPrepared,
    },
    isSmallDevice,
) => {
    const isNightMode = Utils.getIsNightMode();
    let projectsSelected = null;
    // matchMedia is not in utils because is a special case only for images
    // TODO: add projects updating when matchMedia is changed
    if (isSmallDevice && window.matchMedia('(orientation: portrait)').matches) {
        if (isNightMode) {
            projectsSelected = nightPhoneProjectPrepared;
        } else {
            projectsSelected = dayPhoneProjectPrepared;
        }
    } else if (isNightMode) {
        projectsSelected = nightDesktopProjectPrepared;
    } else {
        projectsSelected = dayDesktopProjectPrepared;
    }
    return projectsSelected;
};

const incrementPositionHelper = (clear, length, position) => {
    let newPosition = null;
    if (!clear) {
        if (position < length) {
            newPosition = position + 1;
        } else {
            newPosition = 1;
        }
    } else {
        newPosition = 1;
    }
    return newPosition;
};

const decrementPositionHelper = (clear, length, position) => {
    let newPosition = null;
    if (!clear) {
        if (position === 1) {
            newPosition = length;
        } else if (position > 1) {
            newPosition = position - 1;
        }
    } else {
        newPosition = 1;
    }
    return newPosition;
};

const resetProjectsHelper = (
    position,
    allProjectsPrepared,
    setMainContainer,
    setProjectsModeSelected,
    isSmallDevice,
) => {
    if (allProjectsPrepared) {
        const projectsSelected = getProjectsByMode(
            allProjectsPrepared,
            isSmallDevice,
        );
        setMainContainer(projectsSelected.find((i) => i.position === position));
        setProjectsModeSelected(projectsSelected);
    }
};

const requestProjects = async (
    position,
    setMainContainer,
    setProjectsModeSelected,
    setAllProjectsPrepared,
    isSmallDevice,
) => {
    const { projects, error } = await getProjects();
    if (!error) {
        const allProjectsPrepared = await prepareProjects(projects, isSmallDevice);
        setAllProjectsPrepared(allProjectsPrepared);
        resetProjectsHelper(
            position,
            allProjectsPrepared,
            setMainContainer,
            setProjectsModeSelected,
            isSmallDevice,
        );
    }
};

const MainProviderContainer = ({ children }) => {
    const { MainProvider: { Provider } } = Utils;
    const { deviceType } = useContext(Utils.ViewPortSizeProvider);
    const [mainContainer, setMainContainer] = useState(null);
    const [position, setPosition] = useState(1);
    const [projectsModeSelected, setProjectsModeSelected] = useState([]);
    const [allProjectsPrepared, setAllProjectsPrepared] = useState(null);

    useEffect(() => {
        const isSmallDevice = deviceType === Utils.DeviceSize.SMALL;
        if (!allProjectsPrepared) {
            requestProjects(
                position,
                setMainContainer,
                setProjectsModeSelected,
                setAllProjectsPrepared,
                isSmallDevice,
            );
        } else {
            resetProjectsHelper(
                position,
                allProjectsPrepared,
                setMainContainer,
                setProjectsModeSelected,
                isSmallDevice,
            );
        }
    }, [position, deviceType, allProjectsPrepared]);

    const incrementPosition = (clear) => {
        const { length } = projectsModeSelected;
        const newPosition = incrementPositionHelper(clear, length, position);
        setPosition(newPosition);
        setMainContainer(projectsModeSelected.find((i) => i.position === newPosition));
    };

    const decrementPosition = (clear) => {
        const { length } = projectsModeSelected;
        const newPosition = decrementPositionHelper(clear, length, position);
        setPosition(newPosition);
        setMainContainer(projectsModeSelected.find((i) => i.position === newPosition));
    };

    const resetProjects = () => {
        const isSmallDevice = deviceType === Utils.DeviceSize.SMALL;
        resetProjectsHelper(
            position,
            allProjectsPrepared,
            setMainContainer,
            setProjectsModeSelected,
            isSmallDevice,
        );
    };

    return (
        <Provider
            value={{
                incrementPosition,
                decrementPosition,
                position,
                numberOfProjects: projectsModeSelected?.length,
                mainContainer,
                resetProjects,
            }}
        >
            {children}
        </Provider>
    );
};

export default MainProviderContainer;
