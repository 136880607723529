/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, {
    Fragment, useState, useContext, cloneElement,
} from 'react';
import Header from '../header';
import Footer from '../footer';
import Menu from './menu';
import Utils from '../../utils';
import GlobalStyle from './GlobalStyle';
import { LuzOffContainer } from './styles';

const BasePage = ({
    children, deviceType, onPage,
}) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const openMenu = (e) => {
        e.preventDefault();
        setIsMenuOpen(true);
    };

    const closeMenu = (e) => {
        e.preventDefault();
        if (isMenuOpen) {
            setIsMenuOpen(false);
        }
    };

    const { settingNightMode } = useContext(Utils.NightModeProvider);
    const isNightMode = Utils.getIsNightMode();
    const { mainContainer } = useContext(Utils.MainProvider);
    const childrenCloned = cloneElement(
        children, { isNightMode/* , onClick: (e) => closeMenu(e) */ },
    );

    return (
        <Fragment>
            <Header
                deviceType={deviceType}
                onPage={onPage}
                openMenu={openMenu}
                isNightMode={isNightMode}
                settingNightMode={settingNightMode}
                onClick={closeMenu}
            />
            {childrenCloned}
            <Footer
                deviceType={deviceType}
                onPage={onPage}
                isNightMode={isNightMode}
                onClick={closeMenu}
            />
            <LuzOffContainer
                isNightMode={isNightMode}
                onClick={settingNightMode}
                isMain={onPage === Utils.Page.MAIN}
            >
                <p>Luz OFF</p>
            </LuzOffContainer>
            <GlobalStyle
                isNightMode={isNightMode}
                mainContainer={mainContainer}
                onPage={onPage}
            />
            {
                deviceType === Utils.DeviceSize.SMALL && (
                    <Menu
                        isMenuOpen={isMenuOpen}
                        onPage={onPage}
                        closeMenu={closeMenu}
                        isNightMode={isNightMode}
                    />
                )
            }
        </Fragment>
    );
};

export default BasePage;
