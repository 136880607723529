import { createGlobalStyle, css } from 'styled-components';
import Utils from '../../utils';

const setBackgroundColor = (mainContainer, isNightMode, onPage) => {
    let color = null;
    if (onPage === Utils.Page.MAIN && mainContainer) {
        color = mainContainer.backgroundColor;
    } else if (isNightMode) {
        color = css`#161718`;
    } else {
        color = css`#c7c8ca`;
    }
    return color;
};

const setBackgroundImage = (mainContainer, onPage) => {
    let backgroundImage = css``;
    if (onPage === Utils.Page.MAIN && mainContainer?.backgroundImage) {
        backgroundImage = css`
            background-image: url(${mainContainer.backgroundImage});
            background-repeat: no-repeat;
            background-size: cover, cover;
            background-position: center;
        `;
    }
    return backgroundImage;
};

export default createGlobalStyle`
    html, body, #root {
        width: 100%;
        height: 100%;
        margin: 0;
        background-color: ${({ mainContainer, isNightMode, onPage }) => setBackgroundColor(mainContainer, isNightMode, onPage)};
        ${({ mainContainer, onPage }) => setBackgroundImage(mainContainer, onPage)};
    }

    ::-webkit-scrollbar {
        display: none;
    }

    * {
        -webkit-font-smoothing: antialiased;
        font-family: neue-haas-grotesk-text, sans-serif;

        &::-webkit-scrollbar {
            display: none;
        }

        ::selection {
            color: ${({ isNightMode }) => (isNightMode ? 'black;' : 'white')};
            background: ${({ isNightMode }) => (isNightMode ? 'white;' : 'black')};
        }
    }
`;
