import React, { lazy, Suspense } from 'react';
import Utils from '../../utils';

const footeLarge = lazy(() => import('./footer-large'));
const footeSmall = lazy(() => import('./footer-small'));

const generateFooter = (deviceType, onPage) => {
    let footer = null;
    if (
        (
            onPage !== Utils.Page.PROFILE
            && onPage !== Utils.Page.CONTACT
        ) && (
            deviceType === Utils.DeviceSize.LARGE
            || deviceType === Utils.DeviceSize.MEDIUM
        )
    ) {
        footer = footeLarge;
    } else if (
        deviceType === Utils.DeviceSize.SMALL
    ) {
        footer = footeSmall;
    }
    return footer;
};

const FooterContainer = ({ deviceType, onPage, isNightMode }) => {
    const Footer = generateFooter(deviceType, onPage);
    if (Footer) {
        return (
            <Suspense fallback={null}>
                <Footer onPage={onPage} deviceType={deviceType} isNightMode={isNightMode} />
            </Suspense>
        );
    }
    return null;
};

export default FooterContainer;
