import React, { lazy, Suspense } from 'react';
import Utils from '../../utils';

const headerLarge = lazy(() => import('./header-large'));
const headerSmall = lazy(() => import('./header-small'));

const generateHeader = (deviceType) => {
    let header = null;
    if (
        deviceType === Utils.DeviceSize.LARGE
        || deviceType === Utils.DeviceSize.MEDIUM
        || (
            deviceType === Utils.DeviceSize.SMALL
            && window.matchMedia('(orientation: landscape)').matches
        )
    ) {
        header = headerLarge;
    } else if (deviceType === Utils.DeviceSize.SMALL) {
        header = headerSmall;
    }
    return header;
};

const HeaderContainer = ({
    deviceType, onPage, openMenu, isLandscape, isNightMode, settingNightMode,
}) => {
    const Header = generateHeader(deviceType, isLandscape);
    return (
        <Suspense fallback={null}>
            <Header
                onPage={onPage}
                openMenu={openMenu}
                deviceType={deviceType}
                isNightMode={isNightMode}
                settingNightMode={settingNightMode}
            />
        </Suspense>
    );
};

export default HeaderContainer;
