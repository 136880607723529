import React, { lazy, Suspense } from 'react';
import Utils from '../../utils';

const contactLarge = lazy(() => import('./contact-large'));
const contactSmall = lazy(() => import('./contact-small'));

const generateContact = (deviceType) => {
    let contact = null;
    if (deviceType === Utils.DeviceSize.LARGE || deviceType === Utils.DeviceSize.MEDIUM) {
        contact = contactLarge;
    } else if (deviceType === Utils.DeviceSize.SMALL) {
        contact = contactSmall;
    }
    return contact;
};

const ContactContainer = ({
    deviceType, onPage,
}) => {
    const Contact = generateContact(deviceType);
    return (
        <Suspense fallback={null}>
            <Contact
                deviceType={deviceType}
                onPage={onPage}
            />
        </Suspense>
    );
};

export default ContactContainer;
