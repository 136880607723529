const getProjects = async () => {
    let projects = [];
    let error = null;
    try {
        const result = await fetch(`${window.location.origin}/api/getProjects`);
        if (result) {
            const resultJSON = await result.json();
            if (resultJSON.projects) {
                projects = resultJSON.projects;
            }

            if (resultJSON.error) {
                throw new Error(JSON.stringify(resultJSON.error));
            }
        }
    } catch (err) {
        console.error(err);
        error = err;
    }
    return {
        projects,
        error,
    };
};

export default getProjects;
