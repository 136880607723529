import styled, { css } from 'styled-components';

// height is calculated in this way bc the "terminos y condiciones" footer has 30px of size

const Container = styled.div`
    width: inherit;
    height: inherit;
`;

const MenuStyled = styled.div`
    position: fixed;
    height: 100%;
    width: 80%;
    right: 0;
    background-color: #FFDC00;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: transform 0.5s;
    z-index: 2;

    ${(props) => !props.isMenuOpen && `
        transform: translateX(100%);
    `}
`;

const CloseButtonDiv = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    background-color: Transparent;
`;

const CloseButton = styled.button`
    border: 0;
    background-color: Transparent;
    padding-top: 30px;
    padding-right: 20px;

    & hr {
        width: 16px;
        height: 3px;
        background: black;
        border: 0;
        margin: 0;
    }
`;

const LinksContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;

    & div:first-child {
        margin-top: 25px;
    }

    & div {
        margin-top: 25px;
        padding-left: 36px;
    }
`;

const LinkContainer = styled.div`
    text-align: end;
    padding-right: 20px;

    & a {
        text-decoration: underline;
        font-size: 36px;
        font-weight: 400;
        color: black;
        ${(props) => props.selected && 'font-style: italic; !important; color: red; text-decoration: none;'} 
    }
`;

const SaveEnergyContainer = styled.div`
    display: flex;

    & div {
        padding-left: 36px;
        padding-bottom: 33px;

        & p {
            font-weight: 400;
            margin: 0;
        }

        & p:first-child {
            font-size: 14px;
        }

        & p:last-child {
            font-size: 36px;
        }
    }
`;

const Img = styled.img`
    width: 24px;
    height: 24px;
    margin-left: 1px;
    transform: translateY(-2px);
`;

const CrossImg = styled.img`
    width: 25px;
`;

const ALink = styled.a`
    text-decoration: none;
    color: black;
    font-weigth: 400;
`;

const WrapperStyled = styled.div`
`;

const LastDivContainer = styled.div`
    text-align: end;
    padding-right: 20px;
    padding-bottom: 20px;

    & p {
        margin: 0;
    }
`;

const ImgHandSmall = styled.img`
    width: 16px;
    margin: 0px 5px 0px 5px;
`;

const getLinkStyle = (isMain, isNightMode) => {
    let style = null;
    if (isMain) {
        style = css`
            filter: invert(1) grayscale(1);
            mix-blend-mode: difference;
        `;
    } else if (isNightMode) {
        style = css`
            color: white !important;
        `;
    } else {
        style = css`
            color: black !important;
        `;
    }
    return style;
};

const LuzOffContainer = styled.div`
    position: fixed;
    bottom: 0;
    right: 0;
    text-decoration: underline;
    padding-right: 20px;
    cursor: pointer;
    z-index: 2;
    ${({ isMain, isNightMode }) => getLinkStyle(isMain, isNightMode)}
`;

const HagamosAlgoContainer = styled.div`
    font-size: 16px;

    & a {
        color: black;
    }
`;

export {
    MenuStyled,
    CloseButtonDiv,
    CloseButton,
    LinksContainer,
    SaveEnergyContainer,
    Img,
    CrossImg,
    LinkContainer,
    Container,
    WrapperStyled,
    ALink,
    LastDivContainer,
    ImgHandSmall,
    LuzOffContainer,
    HagamosAlgoContainer,
};
