import React, { useState, useEffect } from 'react';
import Utils from '../../utils';

const ViewPortSizeProviderContainer = ({ children }) => {
    const { ViewPortSizeProvider: { Provider }, getDeviceSize } = Utils;
    let deviceValues = getDeviceSize();
    const [deviceType, setDeviceType] = useState(deviceValues.deviceType);
    const [VH, setVH] = useState(deviceValues.VH);
    const [VW, setVW] = useState(deviceValues.VW);

    const setDeviceTypeHelper = () => {
        deviceValues = getDeviceSize();
        setDeviceType(deviceValues.deviceType);
        setVH(deviceValues.VH);
        setVW(deviceValues.VW);
    };

    useEffect(() => {
        window.addEventListener('resize', setDeviceTypeHelper);
        return () => window.removeEventListener('resize', setDeviceTypeHelper);
    });

    return (
        <Provider value={{ deviceType, VH, VW }}>
            {children}
        </Provider>
    );
};

export default ViewPortSizeProviderContainer;
