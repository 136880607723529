import React from 'react';
import LanguageProvider from './lang';

import {
    PageSwitcher,
    ViewPortSizeProviderContainer,
    NightModeProviderContainer,
    MainProviderContainer,
} from './components';

const App = () => (
    <LanguageProvider>
        <ViewPortSizeProviderContainer>
            <MainProviderContainer>
                <NightModeProviderContainer>
                    <PageSwitcher />
                </NightModeProviderContainer>
            </MainProviderContainer>
        </ViewPortSizeProviderContainer>
    </LanguageProvider>
);

export default App;
