/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { Link } from 'react-router-dom';
import {
    MenuStyled, CloseButtonDiv, CloseButton, LinksContainer,
    LinkContainer, LastDivContainer,
    ALink, ImgHandSmall, HagamosAlgoContainer,
} from './styles';
import Utils from '../../utils';
import handDay from '../../assets/mano_dia.svg';

const Menu = ({
    closeMenu, isMenuOpen, onPage,
}) => (
    <MenuStyled isMenuOpen={isMenuOpen}>
        <CloseButtonDiv>
            <CloseButton onClick={closeMenu}><hr /></CloseButton>
        </CloseButtonDiv>
        <LinksContainer
            selected={onPage === Utils.Page.MAIN}
        >
            <LinkContainer
                selected={onPage === Utils.Page.MAIN}
                onClick={(e) => closeMenu(e)}
            >
                <Link to={Utils.Paths.MAIN}>
                    Home
                </Link>
            </LinkContainer>
            <LinkContainer
                selected={onPage === Utils.Page.PROFILE}
                onClick={(e) => closeMenu(e)}
            >
                <Link to={Utils.Paths.PROFILE}>
                    About
                </Link>
            </LinkContainer>
            <LinkContainer
                selected={onPage === Utils.Page.CONTACT}
                onClick={(e) => closeMenu(e)}
            >
                <Link to={Utils.Paths.CONTACT}>
                    Contacto
                </Link>
            </LinkContainer>
            <LinkContainer>
                <ALink href={Utils.Urls.LINKEDIN} rel="noreferrer" target="_blank">Linkedin</ALink>
            </LinkContainer>
        </LinksContainer>
        <LastDivContainer>
            <p>
                <ImgHandSmall
                    src={handDay}
                />
                Ey!
            </p>
            <p>¿Tienes una idea?</p>
            <HagamosAlgoContainer>
                <Link to={Utils.Paths.CONTACT} onClick={(e) => closeMenu(e)}>
                    Hagamos algo juntos!
                </Link>
            </HagamosAlgoContainer>
        </LastDivContainer>
    </MenuStyled>
);

export default Menu;
