import React from 'react';
import { IntlProvider } from 'react-intl';
import esMessages from './translations/es.json';

const messages = {
    es: esMessages,
};

const defaultLocale = 'es';

const LanguageProvider = ({ children }) => (
    <IntlProvider
        locale={defaultLocale}
        messages={messages[defaultLocale]}
    >
        {React.Children.only(children)}
    </IntlProvider>
);

export default LanguageProvider;
