import { createContext } from 'react';
import DeviceDetector from 'device-detector-js';

const Page = {
    MAIN: 'main',
    PROFILE: 'profile',
    CONTACT: 'contact',
};

const DeviceSize = {
    SMALL: 'SMALL',
    MEDIUM: 'MEDIUM',
    LARGE: 'LARGE',
};

const DeviceType = {
    DESKTOP: 'DESKTOP',
    PHONE: 'PHONE',
};

const Paths = {
    MAIN: '/',
    PROFILE: '/about',
    CONTACT: '/contacto',
};

const Mode = {
    NIGHT: 'NIGHT',
    DAY: 'DAY',
};

const getViewPortSize = () => {
    const VW = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
    const VH = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
    return { VW, VH };
};

const getDeviceSize = () => {
    const { VW, VH } = getViewPortSize();
    let deviceType = null;
    const deviceDetector = new DeviceDetector();
    const { device: { type } } = deviceDetector.parse(navigator.userAgent);

    if (
        (VW <= 600
        || (window.matchMedia('(orientation: landscape)').matches && VH <= 600)
        || navigator.userAgent.includes('Instagram'))
    ) {
        deviceType = DeviceSize.SMALL;
    } else if (
        (VW > 600 && VW <= 992)
        || (
            window.matchMedia('(orientation: landscape)').matches
            && ((VH > 600) && (VH <= 992))
            && (type !== 'desktop')
        )
    ) {
        deviceType = DeviceSize.MEDIUM;
    } else {
        deviceType = DeviceSize.LARGE;
    }
    return { deviceType, VW, VH };
};

const ViewPortSizeProvider = createContext({ ...getDeviceSize() });
const NightModeProvider = createContext({ isNightMode: false });
const MainProvider = createContext({ component: null, color: null, fullImage: null });
const getIsNightMode = () => {
    const isNightModeValue = sessionStorage.getItem('isNightMode');
    const isNightMode = isNightModeValue ? isNightModeValue === 'true' : false;
    return isNightMode;
};

const Urls = {
    JOHN_PEPE: 'https://johnpepe.es/',
    CREATIVE_AGENCY: 'https://creativexagency.com/',
    MAKE14: 'https://www.make14.com/',
    IED: 'https://iedmadrid.com',
    OPINNO: 'https://www.opinno.com/',
    ESI: 'http://www.esivalladolid.com/',
    CUNEF: 'https://www.cunef.edu/',
    EAG: 'https://www.escuelaartegranada.com/',
    TP_MBA: 'https://thepowermba.com/es/',
    TP_MBA_MA: 'https://thepowermba.com/es/',
    IDEO: 'https://www.ideo.com/eu',
    LINKEDIN: 'https://www.linkedin.com/in/jaimelinan/',
    CHESS: 'https://www.chess.com/member/jairov',
    SUPERCOLLECTIVE: 'https://jaimelf.com',
};

const TLF = '034615068816';
const MAIL = 'hola@jaimelf.com';
const TLF_TEXT = '+34 615 068 816';
const MAIL_TEXT = 'hola(at)jaimelf.com';
const LINKEDIN_TEXT = 'LinkedIn';

export {
    Page,
    ViewPortSizeProvider,
    getViewPortSize,
    getDeviceSize,
    DeviceSize,
    NightModeProvider,
    Paths,
    Urls,
    TLF,
    MAIL,
    TLF_TEXT,
    MAIL_TEXT,
    LINKEDIN_TEXT,
    MainProvider,
    getIsNightMode,
    DeviceType,
    Mode,
};
