import styled from 'styled-components';

const Component = styled.img`
    ${({ objectFitContain }) => objectFitContain && 'object-fit: contain;'}
    ${({ height }) => height && `height: ${height};`}
    ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth};`}
    ${({ width }) => width && `width: ${width};`}
    ${({ translationY }) => translationY && `transform: translateY${translationY});`}
    ${({ translationX }) => translationX && `transform: translateX${translationX});`}
`;

export {
    // eslint-disable-next-line import/prefer-default-export
    Component,
};
