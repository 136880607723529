import React, { lazy, Suspense } from 'react';
import Utils from '../../utils';

const mainLarge = lazy(() => import('./main-large'));
const mainSmall = lazy(() => import('./main-small'));
const mainLandscape = lazy(() => import('./main-small-landscape'));

const generateMain = (deviceType) => {
    let main = null;
    if (
        deviceType === Utils.DeviceSize.LARGE
        || deviceType === Utils.DeviceSize.MEDIUM
    ) {
        main = mainLarge;
    } else if (deviceType === Utils.DeviceSize.SMALL && !window.matchMedia('(orientation: landscape)').matches) {
        main = mainSmall;
    } else if (deviceType === Utils.DeviceSize.SMALL && window.matchMedia('(orientation: landscape)').matches) {
        main = mainLandscape;
    }
    return main;
};

const MainContainer = ({
    deviceType, onPage,
}) => {
    const Main = generateMain(deviceType);
    return (
        <Suspense fallback={null}>
            <Main onPage={onPage} />
        </Suspense>
    );
};

export default MainContainer;
