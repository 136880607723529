import React, { useContext } from 'react';
import Utils from '../../utils';

const NightModeProviderContainer = ({ children }) => {
    const { NightModeProvider: { Provider } } = Utils;

    if (!Utils.getIsNightMode()) {
        sessionStorage.setItem('isNightMode', false);
    }

    const { resetProjects } = useContext(Utils.MainProvider);

    const settingNightMode = (e) => {
        e.stopPropagation();
        e.preventDefault();
        const isNightMode = Utils.getIsNightMode();
        sessionStorage.setItem('isNightMode', !isNightMode);
        resetProjects();
    };

    return (
        <Provider value={{ settingNightMode }}>
            {children}
        </Provider>
    );
};

export default NightModeProviderContainer;
