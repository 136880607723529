import React, { lazy, Suspense } from 'react';
import Utils from '../../utils';

const profileLarge = lazy(() => import('./profile-large'));
const profileSmall = lazy(() => import('./profile-small'));

const generateProfile = (deviceType) => {
    let profile = null;
    if (deviceType === Utils.DeviceSize.LARGE || deviceType === Utils.DeviceSize.MEDIUM) {
        profile = profileLarge;
    } else if (deviceType === Utils.DeviceSize.SMALL) {
        profile = profileSmall;
    }
    return profile;
};

const ProfileContainer = ({
    deviceType, onPage,
}) => {
    const Profile = generateProfile(deviceType);
    return (
        <Suspense fallback={null}>
            <Profile
                deviceType={deviceType}
                onPage={onPage}
            />
        </Suspense>
    );
};

export default ProfileContainer;
