import React, { useContext, useEffect } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from 'react-router-dom';
import Utils from '../../utils';
import { Main, Profile, Contact } from '../../pages';

const PageSwitcher = () => {
    const locationReload = () => {
        // eslint-disable-next-line no-restricted-globals
        location.reload();
    };

    useEffect(() => {
        window.addEventListener('orientationchange', locationReload);
        return () => {
            window.removeEventListener('orientationchange', locationReload);
        };
    }, []);

    const { deviceType } = useContext(Utils.ViewPortSizeProvider);

    return (
        <Router>
            <Switch>
                <Route exact path={Utils.Paths.MAIN}>
                    <Main deviceType={deviceType} onPage={Utils.Page.MAIN} />
                </Route>
                <Route path={Utils.Paths.PROFILE}>
                    <Profile deviceType={deviceType} onPage={Utils.Page.PROFILE} />
                </Route>
                <Route path={Utils.Paths.CONTACT}>
                    <Contact deviceType={deviceType} onPage={Utils.Page.CONTACT} />
                </Route>
            </Switch>
        </Router>
    );
};

export default PageSwitcher;
